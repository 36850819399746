import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import "./index.css";

import i18n from "./i18n";
import configureStore from "./redux/configureStore";
import AppContiner from "./AppContiner";
import * as serviceWorker from "./serviceWorker";
import Loader from "./components/common/Loader";
import { pdfjs } from "react-pdf";
import * as Sentry from "@sentry/react";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const store = configureStore();

Sentry.init({
  dsn: "https://21034dfec171c7b6e3e1913dbe18fee1@o4506399144804352.ingest.us.sentry.io/4507656946647040",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  // <React.StrictMode>
  <Suspense fallback={<Loader />}>
    <ReduxProvider store={store}>
      <Router>
        <I18nextProvider i18n={i18n}>
          <AppContiner />
        </I18nextProvider>
      </Router>
    </ReduxProvider>
  </Suspense>,
  // </React.StrictMode>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
