import React, { lazy } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { jssPreset } from "@material-ui/core/styles";
import { appSelector } from "./selectors/appSelector";
//import App from "./App";
import { create } from "jss";
import rtl from "jss-rtl";
import moment from "moment/min/moment-with-locales";
import Moment from "react-moment";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const App = lazy(() => import("./App"));

function AppContiner() {
  Moment.globalMoment = moment;
  const {
    screenPosition,
    theme,
    isLoggedIn,
    momentFormat,
    momentTimeFormat,
    momentTimeShortFormat,
  } = useSelector((state) => {
    const app = appSelector(state);
    const profile =
      state.user.info && state.user.info.profile
        ? state.user.info.profile
        : null;
    return {
      screenPosition: app.screenPosition,
      theme: app.theme,
      momentFormat:
        profile && profile.metadata && profile.metadata.dateFormat
          ? profile.metadata.dateFormat
          : "DD MMM YYYY",
      momentTimeFormat:
        profile && profile.metadata && profile.metadata.timeFormat
          ? profile.metadata.timeFormat
          : "LTS",
      momentTimeShortFormat:
        profile && profile.metadata && profile.metadata.timeFormat
          ? profile.metadata.timeFormat
          : "LT",
      isLoggedIn: state.auth.renewTokenStatus === "OK",
    };
  });
  Moment.globalFormat = momentFormat;
  Moment.globalTimeFormat = momentTimeFormat;
  Moment.globalTimeShortFormat = momentTimeShortFormat;
  return (
    <App
      isLoggedIn={isLoggedIn}
      screenPosition={screenPosition}
      jss={jss}
      themeStr={theme}
    />
  );
}

AppContiner.propTypes = {};

export default React.memo(AppContiner);
